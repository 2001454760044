import { slotted } from "utils/template-literal-tags";

const staticTexts = {
  navigation: {
    main: "Översikt",
    guides: "Guidemallar",
    calendars: "Kalendermallar",
    guide: "Certifieringsguiden",
    manual: "KMA-Manualen",
    documents: "Dokument & mallar",
    services: "Tjänster & verktyg",
    calendar: "Årskalender",
    skills: "Kompetens",
    supervision: "Handledning",
    topics: "A-Ö",
    help: "Hjälp & support",
    companies: "Företag",
    users: "Användare",
    supervisors: "Handledare",
    news: "Nyheter",
    workbooks: "Fördjupning",
    admin: "Admin",
    servicesAndTools: "Tjänster & verktyg",
    section: "Avsnitt",
    of: "av",
    sectionsDone: "avsnitt klara",
    requirementsDone: "krav klara",
    sectionsMissing: "Avsnitt saknas",
    requirementsMissing: "Krav saknas",
  },
  productTypes: {
    ordning: "Ordning",
    certifiering: "Certifiering",
    iso: "ISO",
  },
  document: {
    title: {
      serviceAdmin: "Dokument & mallar",
      companyAdmin: "Dokument & mallar",
      companyEditor: "Dokument & mallar",
      companyReader: "Dokument & mallar",
      supervisor: "Dokument & mallar",
    },
    description: {
      serviceAdmin: [
        "Nedan finns de dokument och mallar som företagets administratörer och redaktörer kommer åt. ",
        "Originalen finns sparade i Byggföretagens SharePoint och laddas upp som kopior till denna sida. Lägg inte upp dokument som inte finns i Sharepoint. ",
        "Dokumentens litterera är uppsatt enligt rutin för dokument.",
      ],
      companyAdmin: [
        "Nedan finns de dokument och mallar som ert företags administratörer och redaktörer kommer åt. ",
        "Ni laddar med fördel ner dem till er lokala dator, redigerar dem och sparar dem på lämpligt ställe, gärna i ”Certifieringsguiden”.",
      ],
      companyEditor: [
        "Nedan finns de dokument och mallar som ert företags administratörer och redaktörer kommer åt. ",
        "Ni laddar med fördel ner dem till er lokala dator, redigerar dem och sparar dem på lämpligt ställe, gärna i ”Certifieringsguiden”.",
      ],
      companyReader: [
        "Nedan finns de dokument och mallar som ert företag sparat och som är relevanta för er verksamhet.",
      ],
      supervisor: [
        "Nedan finns de dokument och mallar som era företags administratörer och redaktörer kommer åt. ",
        "De laddar med fördel ner dokumenten till sin lokala dator, redigerar dem och sparar dem på lämpligt ställe, gärna i ”Certifieringsguiden”.",
      ],
      default: [""],
    },
    title_label: "Titel",
    description_label: "Beskrivning",
    literation: "Litterering",
    file: "Fil",
    addFile: "Ladda upp fil",
    newFile: "Ny fil",
    newVersionButton: "Lägg till ny version",
    newVersion: "Ny version",
    productType: "Behörighet",
    search: "Sök på exempelvis titel, beskrivning eller litterering",
    delete: "Radera",
    edit: "Redigera",
    newDoc: "Lägg till nytt dokument",
    editDoc: "Redigera dokument",
    copy: "Kopiera länk",
    copySuccess: "Länken är kopierad",
    show: "Visa",
    showMore: "Visa mer",
    download: "Ladda ner",
    uploaded_docs: "Uppladdade dokument",
    back: "Visa alla dokument",
    addNew: "Lägg till nytt dokument",
    versions: (amount) => `(${amount} ${amount > 1 ? `versioner` : `version`})`,
    versionsHeading: slotted`Versionshistorik för "${`[documentTitle]`}"`,
    versionsBack: slotted`Gå tillbaka till ${`[title]`}`,
    updated: slotted`Senast uppdaterad ${`[updatedAt]`} av ${`[updatedBy]`}`,
    favourite: "Favoritmarkera dokumentet",
    unfavourite: "Ta bort som favorit",
    deleteConfirmHeading: `Ta bort dokument`,
    deleteConfirmText: slotted`Är du säker på att du vill ta bort "${`[documentTitle]`}"?`,
    deleteConfirm: "Radera",
    deleteCancel: "Avbryt",
    library_document_not_found: "Originalmallen kunde inte hittas",
  },
  upload: {
    managementOnly: "Endast synlig för ledningsgruppen",
    upload: "Ladda upp",
    add: "Lägg till ett dokument",
    cancel: "Avbryt",
    close: "Stäng",
    loading: "Laddar upp...",
    success: "Ditt dokument är uppladdat",
    error_title_is_required: "Titel är obligatorisk",
    error_file: "Det gick inte att ladda upp filen - försök igen",
    error_general: "Någonting gick fel - vänligen försök igen senare",
  },
  guide: {
    title: "Titel",
    type: "Utgå från guidemall",
    productType: "Produkt",
    delete: "Radera",
    edit: "Redigera",
    createNew: "Skapa ny guidemall",
    createNewTitle: "Skapa guidemall",
    existingGuide: "Att kopiera",
    editGuideSettings: "Redigera inställningar",
    editGuide: "Redigera guidemall",
    removeGuideTitle: "Ta bort guidemallen",
    showCompanies: "Visa företag",
    all_guides: "Alla guidemallar",
    description: "Skapa nya guidemallar som företagen sedan ansluts till.",
    export: "Exportera och ladda ner som företagsmanual",
    exportProgress: "Vänligen vänta",
    preview: "Förhandsgranskning av guidemall",
    previewBack: "Tillbaka till guidebyggaren",
    saveWarning: (count) => `Det här kravet kommer uppdateras för ${count} företag.`,
    onlyInGuideTemplate: `Det här kravet finns endast i guidemallen.`,
    saveRequirement: "Spara",
    confirmDeletion: "Är du säker på att du vill ta bort guidemallen?",
    toolbar: {
      read_more: "Läs på",
      read_more_tooltip: "Lär dig allt du behöver veta i Arbetsboken",
      assign: "Tilldela",
      assign_tooltip: "Sätt en ansvarig för avsnittet",
      comment: "Kommentera",
      comment_tooltip: "Kommentera avsnittet",
      done: "Klart",
      done_tooltip: "Markera som klart",
      undone_tooltip: "Avmarkera avsnittet",
    },
    part: {
      add_part: "Lägg till delprocess",
      part_title_default: "Delrubrik",
    },
    section: {
      add_section: "Lägg till avsnitt",
      section_title_default: "Avsnittsrubrik",
      choose_chapter: "Välj kapitel",
    },
    routine: {
      title: "Vårt sätt att arbeta",
      subtitle: "Våra rutiner",
      document_title: "Rutindokument",
      cancel: "Avbryt",
      save: "Spara",
      edit: "Redigera",
      descriptionMissing: "Text saknas",
      placeholderDescription: "Ange beskrivning",
      placeholderTitle: "Ange rubrik",
    },
    mainRequirement: {
      add_main_requirement: "Lägg till kravsamling",
      add_main_requirement_name: "Lägg till namn på kravsamling",
      main_requirement_label: "Namn på kravsamling",
    },
    requirement: {
      requirement_title: "Att göra",
      requirement_viewer_title: "Relaterade dokument",
      requirement_missing: "Krav saknas",
      requirement: "Krav",
      done_tooltip: "Markera som klart",
      undone_tooltip: "Avmarkera",
      assign_tooltip: "Sätt en ansvarig för kravet",
      comment_tooltip: "Kommentera",
      add_requirement: "Lägg till krav",
      hide: "Dölj kravet för företaget",
      show: "Visa kravet för företaget",
      not_relevant: " - Ej relevant",
    },
    responseslot: {
      upload: "Ladda upp dokument",
      uploadMore: "Ladda upp flera",
      add_responseslot: "Lägg till dokumentuppladdning",
    },
  },
  placeholder: {
    documentName: "Ange dokuments titel",
    documentDescription: "Ange dokumentets beskrivning",
    documentLiteration: "Ange dokumentets literation",
    guideTitle: "Ange guidemallens titel",
    email: "Ange email",
  },
  search: {
    placeholder: "Ange sökord",
  },
  filter: {
    all: "Alla",
  },
  users: {
    user: {
      serviceAdmin: "Användare",
      companyAdmin: "Användare",
      companyEditor: "Användare",
      companyReader: "Användare",
      supervisor: "Användare",
    },
    description: {
      serviceAdmin: [
        "Här listas alla användare som har tillgång tjänsten och loggat in med koppling till aktuellt företag. ",
        "Som tjänsteadministratör kan du även se om användaren varit inloggat och vilken roll de har.",
      ],
      companyAdmin: [
        "Här listas alla användare som har tillgång tjänsten och loggat in med koppling till ert företag. ",
        "Som företagsadministratör kan du även se vilken roll de har.",
      ],
      companyEditor: ["Här listas alla användare som har tillgång tjänsten."],
      companyReader: [],
      supervisor: [
        "Här listas alla användare som har tillgång tjänsten och loggat in med koppling till aktuellt företag. ",
        "Som handledare kan du även se vilken roll en viss person har inom företaget.",
      ],
      default: [""],
    },
    pending: "Väntande användare",
    regular: "Befintliga användare",
    blocked: "Spärrade användare",
    search: "Sök på namn, företag eller email",
    editUser: slotted`Redigera användare ${`[userName]`}`,
    companiesTitle: "Användarens företag",
    neverLoggedIn: "Aldrig loggat in",
    notLoggedIn: "Ej inloggad",
    allCompanies: "Den här användaren är kopplad till följande företag:",
    multipleCompanies: "Den här användaren är kopplad till flera företag och rollen kommer uppdateras på samtliga.",
    multipleAssignmentToReader: "Flerbolagsanvändare med tilldelade uppgifter kan inte ges behörigheten läsare",
    newAssigneeLabel: "Tilldela uppgifterna till",
    editUserSubmit: "Uppdatera användaren",
    deleteUser: "Radera användare",
    deleteUserHeading: slotted`Är du säker på att du vill ta bort ${`[userName]`}?`,
    stateRole: "Ange roll",
  },
  supervisors: {
    Supervisoractive: "Synlig för uppdrag",
    supervisor: "Handledare",
    description: "Nedan listas alla tillgängliga handledare.",
    search: "Sök på namn eller email",
    add: "Lägg till ny handledare",
    email: "Ange email",
    edit: "Redigera",
    fName: "Förnamn",
    lName: "Efternamn",
    selected: "Er handledare",
    missing: "Ingen handledare vald",
    showCompanies: "Visa företag",
    phone: "Telefonnummer",
    editSupervisor: "Redigera handledare",
    delete: "Radera handledare",
    deletsupervisor: "Tabort handledare",
    deleteConfirmText: slotted`Är du säker på att du vill ta bort "${`[fullName]`}"?`,
    deleteCancel: "Nej, avbryt",
    deleteConfirm: "Ja, radera",
    image: "Profilbild",
    changeImage: "Byt profilbild",
    presentation: "Presentation",
    save: "Spara",
  },
  companies: {
    nonChosen: "Ingen vald",
    createGuide: "Skapa guide",
    saveGuide: "Spara",
    company: "Företag",
    description: "Här listas alla företag som har köpt tjänsten.",
    showGuide: "Visa guide",
    editGuide: "Redigera guide",
    editGuideSettings: "Redigera företagsinställningar",
    search: "Sök på företagsnamn eller organisationsnummer",
    searchList: "Sök...",
    headerSelectorLabel: "Välj företag",
    searchListNoFound: "Inga företag matchar.",
    activateGuide: "Aktivera guide",
    inactivateGuide: "Inaktivera guide",
    companySettings: "Företagsinställningar",
    editGuideTitle: "Inställningar för",
    removeGuideTitle: "Ta bort guiden för",
    createGuideButton: "Skapa en guide",
    editCompanyButton: "Spara",
    notSet: "Ej satt",
    setSupervisor: "Ställ in handledare",
    guideTemplates: "Guidemall",
    calendarTemplates: "Kalendermall",
    supervisors: "Handledare",
    companyAdmins: "Företagsadmins",
    chooseCompanyAdmins: "Välj företagsadmins ...",
    noCompanyAdminMatch: "Ingen träff, skriv in ny email",
    removeGuideConfirm: "Är du säker på att du vill ta bort guiden från företaget?",
    removeGuideConfirmExport:
      "Är du säker på att du vill ta bort guiden från företaget? Guiden har dokument och/eller rutiner sparade. Om du inte exporterar dessa kommer de gå förlorade.",
    exportGuide: "Exportera guiden",
    removeGuideLink: "Ta bort guiden",
    activeGuide: "Guiden är aktiv",
    chooseSupervisor: "Välj handledare",
    notes: "Anteckningar",
  },
  news: {
    news: "Nyheter",
    description: "Adminstrera nyheterna i tjänsten.",
    createNew: "Lägg till nyhet",
    editNews: "Redigera nyhet",
    removeNewsTitle: "Ta bort nyheten",
    createNewsTitle: "Lägg till nyhet",
    editNewsTitle: "Uppdatera nyheten",
    titleLabel: "Titel",
    placeholderTitle: "Ange nyhetstitel",
    descriptionLabel: "Innehåll",
    placeholderDescription: "Ange nyhetsinnehållet",
    visibility: "Synlig för",
    chooseProducts: "Välj produkter",
    noMatch: "Det finns ingen produkt",
    createItem: "Spara",
    editItemButton: "Uppdatera nyhet",
    removeItemLink: "Ta bort nyheten",
    relatedDocuments: "Relaterade dokument",
    chooseDocument: "Välj dokument",
    missingOptions: "Ingen träff",
    confirmDeletion: "Är du säker på att du vill ta bort nyheten?",
    isForManagementOnly: "Endast synligt för ledningsgruppen",
  },
  workbook: {
    title: {
      serviceAdmin: "Fördjupning",
      companyAdmin: "Fördjupning",
      companyEditor: "Fördjupning",
      companyReader: "Fördjupning",
      supervisor: "Fördjupning",
    },
    intro: {
      serviceAdmin: [
        "Fördjupning är ett verktyg som bygger på BUC´s kursbyggare. ",
        "Fördjupning ger en mer djupgående instruktion av KMA-arbetet och berör även företagets utveckling bortom den första certifieringen. ",
        "Idag är innehållet mer av en digital bok, i framtiden ser vi detta som ett verktyg för att sprida interaktiv kunskap.",
      ],
      companyAdmin: [
        "Fördjupning ger en mer djupgående instruktion av KMA-arbetet och berör även företagets utveckling bortom den första certifieringen. ",
        "Idag är innehållet mer av en digital bok, i framtiden ser vi detta som ett verktyg för att sprida interaktiv kunskap.",
      ],
      companyEditor: [
        "Fördjupning ger en mer djupgående instruktion av KMA-arbetet och berör även företagets utveckling bortom den första certifieringen. ",
        "Idag är innehållet mer av en digital bok, i framtiden ser vi detta som ett verktyg för att sprida interaktiv kunskap.",
      ],
      companyReader: [
        "Fördjupning ger en mer djupgående instruktion av KMA-arbetet och berör även företagets utveckling bortom den första certifieringen. ",
        "Idag är innehållet mer av en digital bok, i framtiden ser vi detta som ett verktyg för att sprida interaktiv kunskap.",
      ],
      supervisor: [
        "Fördjupning ger en mer djupgående instruktion av KMA-arbetet och berör även företagets utveckling bortom den första certifieringen. ",
        "Idag är innehållet mer av en digital bok, i framtiden ser vi detta som ett verktyg för att sprida interaktiv kunskap.",
      ],
      default: [""],
    },
    backTo: "Tillbaka till",
    chapter: "Kapitel",
    facts_and_reading: "Fakta & inläsning",
    collapse: "Fäll ihop sektion",
    expand: "Fäll ut sektion",
    close: "Stäng",
    play: "Spela",
    pause: "Pausa",
    video_about: "Video om",
    video: "Video",
    chapter_selector: "Kapitelväljare",
    loading_courses: "Laddar kurser…",
    choose_book: "Välj arbetsbok",
    choose_part: "Välj del",
    choose_chapter: "Välj kapitel",
  },
  table: {
    activeGuides: "Aktiva guider",
    activeCalendars: "Aktiva kalendrar",
    assignable: "Synlig för uppdrag",
    company: "Företag",
    competence: "Behörighet",
    date: "Datum",
    delete: "Radera",
    editSupervisor: "Redigera handledare",
    email: "Epost",
    name: "Namn",
    guide: "Guide",
    version: "Version",
    guideActive: "Aktiv",
    heading: "Rubrik",
    orgNumber: "Org. nr",
    product: "Produkt",
    responsible: "Ansvarig för",
    settings: "Inställningar",
    sort: "Sortera",
    supervisor: "Handledare",
    visible: "Synlighet",
  },
  tools: {
    servicesAndTools: {
      serviceAdmin: "Tjänster & verktyg",
      companyAdmin: "Tjänster & verktyg",
      companyEditor: "Tjänster & verktyg",
      companyReader: "Tjänster & verktyg",
      supervisor: "Tjänster & verktyg",
    },
    introduction: {
      serviceAdmin: [
        "För att stötta företagets KMA-arbete ingår digitala hjälpmedel som en del av abonnemanget. ",
        "Dessa tjänster är utvalda med stor noggrannhet och BKMA utvärderar årligen om fler hjälpmedel skall ingå i abonnemanget.",
      ],
      companyAdmin: [
        "För att stötta ert företags KMA-arbete ingår digitala hjälpmedel som en del av abonnemanget. ",
        "Dessa tjänster är utvalda med stor noggrannhet och BKMA utvärderar årligen om fler hjälpmedel skall ingå i abonnemanget. ",
        "Du söker själv medlemskap hos respektive tjänst genom länkar nedan. När du sedan är inloggad kan du lägga upp andra användare i ert företag som har behov av tjänsterna.",
      ],
      companyEditor: [
        "För att stötta ert företags KMA-arbete ingår digitala hjälpmedel som en del av abonnemanget. ",
        "Dessa tjänster är utvalda med stor noggrannhet och BKMA utvärderar årligen om fler hjälpmedel skall ingå i abonnemanget. ",
        "Er företagsadministratör lägger upp vilka inom ert företag som behöver tjänsten. Därefter kan du som redaktör logga in.",
      ],
      companyReader: [
        "För att stötta ert företags KMA-arbete ingår digitala hjälpmedel som en del av abonnemanget. ",
        "Dessa tjänster är utvalda med stor noggrannhet och BKMA utvärderar årligen om fler hjälpmedel skall ingå i abonnemanget. ",
        "Er företagsadministratör lägger upp vilka inom ert företag som behöver tjänsten. Därefter kan du som läsare logga in.",
      ],
      supervisor: [
        "För att stötta företagets KMA-arbete ingår digitala hjälpmedel som en del av abonnemanget. ",
        "Dessa tjänster är utvalda med stor noggrannhet och BKMA utvärderar årligen om fler hjälpmedel skall ingå i abonnemanget. ",
        "Företaget söker själv medlemskap hos respektive tjänst genom länkar nedan. När de sedan är inloggade kan de lägga upp andra användare i företaget som har behov av tjänsterna.",
      ],
      default: [""],
    },
    toolItems: [
      {
        title: "Byggvarubedömningen",
        description:
          "Byggvarubedömningen bedömer byggrelaterade produkter utifrån dess kemiska innehåll, miljöpåverkan under livscykeln och i förlängningen även social påverkan i leverantörsledet.",
        toolLink: "https://byggvarubedomningen.se",
        callToAction: {
          call: "Har du inte tillgång till tjänsten?",
          link: {
            url: "https://byggvarubedomningen.se/skapa-konto/",
            button: "Skapa konto",
          },
        },
      },
      {
        title: "PQi - kvalitetsmätning av byggprojekt",
        description:
          "PQi är ett molnbaserat och automatiserat kvalitetsverktyg, framtaget tillsammans med branschen. PQi stödjer er som BKMA-anslutet företag att uppfylla och följa upp kraven i certifieringen kopplade till bland annat Kvalitet, Arbetsmiljö/säkerhet, Riskhantering och Erfarenhetsåterföring.",
        toolLink: "https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fbinosight.com%2Fpqi%2Fpqi-for-bkma-medlemmar%2F&data=05%7C02%7Cisak.wallenberg%40byggforetagen.se%7C630708ddfe104e4d723608dd087a9fa6%7C1d019c94da7b49c2bf935ee1bb4b6882%7C0%7C0%7C638676044782553932%7CUnknown%7CTWFpbGZsb3d8eyJFbXB0eU1hcGkiOnRydWUsIlYiOiIwLjAuMDAwMCIsIlAiOiJXaW4zMiIsIkFOIjoiTWFpbCIsIldUIjoyfQ%3D%3D%7C0%7C%7C%7C&sdata=06d8F4lyaPaTBvI%2FClRO9Apw9%2BKcTXoLt4K24Dl5z6E%3D&reserved=0",
        callToAction: {
          call: "Vill du veta mer och komma igång?",
          link: {
            url: "https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fbinosight.com%2Fpqi%2Fpqi-for-bkma-medlemmar%2F&data=05%7C02%7Cisak.wallenberg%40byggforetagen.se%7C630708ddfe104e4d723608dd087a9fa6%7C1d019c94da7b49c2bf935ee1bb4b6882%7C0%7C0%7C638676044782553932%7CUnknown%7CTWFpbGZsb3d8eyJFbXB0eU1hcGkiOnRydWUsIlYiOiIwLjAuMDAwMCIsIlAiOiJXaW4zMiIsIkFOIjoiTWFpbCIsIldUIjoyfQ%3D%3D%7C0%7C%7C%7C&sdata=06d8F4lyaPaTBvI%2FClRO9Apw9%2BKcTXoLt4K24Dl5z6E%3D&reserved=0",
            button: "Kom igång",
          },
        },
      },
      {
        title: "iChemistry – kemikalihanteringssystem",
        description:
          "iChemistry är ett kemikaliehanteringssystem och med hjälp av detta webbaserade verktyg kan ni hålla koll både på era kemiska produkter och de lagkrav och regler som gäller just er verksamhet. Lösningen är uppbyggd för att förenkla och automatisera olika processer och flöden som normalt sett är mycket tidskrävande.",
        toolLink: "https://intersolia.com/sv/ichemistry/",
        callToAction: {
          call: "Har du inte åtkomst?",
          link: {
            url: "https://info.intersolia.com/ichemistry/byggforetagarna",
            button: "Anmäl dig",
          },
        },
      },
      {
        title: "Rambölls lagbevakningstjänst",
        description:
          "Rambölls lagbevakningstjänst är ett webbaserat verktyg som hjälper er att ha kontroll på vilken lagstiftning som berör er i byggbranschen inom KMA-orådet. Ni får en laglista och information om när det sker förändringar i lagstiftningen. Även uppdateringar i BBR och PBL finns med i tjänsten.",
        toolLink: "https://lagbevakning.ramboll.se/login",
        callToAction: {
          call: "",
          link: {
            url: "https://lagbevakning.ramboll.se/login",
            button: "Logga in",
          },
        },
      },
      {
        title: "Byggbranschens miljöutbildning ",
        description:
          "Byggbranschens miljöutbildning är en uppskattad online utbildning särskilt framtagen för bygg- och anläggningsbranschen. För BKMA anslutna företag är den helt gratis.På Byggbranschens utbildningscenter hittar du också mängder av andra utbildningar till reducerade medlemspriser. På rekommenderade BKMA kurser får ni dessutom ytterligare fem procent rabatt. Du hittar kurserna genom att filtrera kursutbudet med BKMA. Logga in och boka på buc.se för att ta del av erbjudandet.",
        toolLink: "https://buc.se/",
        callToAction: {
          call: "Ta del av erbjudandet. ",
          link: {
            url: "https://buc.se/",
            button: "Logga in",
          },
        },
      },
      {
        title: "DOX ",
        description:
          "DOX är dokumenthantering som gör att dokumenten blir tillgängliga, versionshanterade och strukturerade som ni vill ha dem. Förutom dokumenthantering finns andra applikationer att lägga till utanför BKMA:s paket, exempelvis: rondering, 2D/3D-viewer (alla filformat), ärendehantering (position på 2D/3D), ritningsgranskning BEAst",
        toolLink:
          "https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.dox.se%2Fvalj-ratt-dox%2F&data=05%7C01%7Cisak.wallenberg%40byggforetagen.se%7C807592e43d59446bd10308dbb44ff607%7C1d019c94da7b49c2bf935ee1bb4b6882%7C0%7C0%7C638302027742426869%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=8Gm9fOd0Pn9yJp7Sxd4AP7BOdZ8Qr%2F%2FOpqXj%2BHc4Wpw%3D&reserved=0",
        callToAction: {
          call: "Har du inte åtkomst?",
          link: {
            url: "https://eur02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.dox.se%2Fvalj-ratt-dox%2F&data=05%7C01%7Cisak.wallenberg%40byggforetagen.se%7C807592e43d59446bd10308dbb44ff607%7C1d019c94da7b49c2bf935ee1bb4b6882%7C0%7C0%7C638302027742426869%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=8Gm9fOd0Pn9yJp7Sxd4AP7BOdZ8Qr%2F%2FOpqXj%2BHc4Wpw%3D&reserved=0",
            button: "Beställ projekt",
          },
        },
      },
      {
        title: "ID06 Bolagsdeklaration ",
        description: `<p>Få kontroll på arbetsplatsen i alla led genom vår digitala tjänst ID06 Bolagsdeklaration. Följ UE- och leverantörskedjan med vårt digitala verktyg som snabbt hjälper till att bevaka projekt genom hela projekttiden. Med tjänsten visas underentreprenörer och underleverantörers finansiella uppgifter och mycket mer. BKMA:s verktyg ger dig tillgång till: </P>
          <ul> 
            <li>Fritt med användare</li>
            <li>Fritt med bevakning på svenska och utländska företag</li>
            <li>Fritt med användare och skapande av projekt</li>
          </ul>
          <p>Ta kontakt med Håkan Ericsson <a href="mailto:hakan.ericsson@id06.se">hakan.ericsson@id06.se</a></p>
          `,
      },
      {
        title: "HR-appen",
        description: `
          <p>HR-appen är en helhetslösning för effektiv och smidig kommunikation till hela organisationen i mobilen ute på bygget eller datorn.</p>
          <p>HR-appen skapar ordning och reda och ger koll på dokumentation, kontroller, avvikelser, rutiner mm.</p>
          <p>HR-appen är ett mycket bra verktyg för att förenkla och effektivisera internkommunikationen, den gör att ni snabbt och enkelt kan nå ut med nyheter och information till utvalda anställda via push-notiser, samt hålla koll på att alla tagit del av informationen.</p>
          <p>I HR-appen samlar du allt på samma ställe, på det sättet kan du minska antalet system och appar för era medarbetare.</p>
          <p>Ta kontakt för demo med Fredrik Thalin <a href="mailto:fredrik.thalin@roxx.se">fredrik.thalin@roxx.se</a> </p>
        `,
      },
    ],
  },
  usertypes: {
    ServiceAdmin: "Tjänsteadmin",
    CompanyAdmin: "Företagsadmin",
    Supervisor: "Handledare",
    CompanyEditor: "Redaktör",
    CompanyReader: "Läsare",
    Blocked: "Spärrad",
  },
  general: {
    delete: "Radera",
    confirmDelete: "Ja, ta bort",
    save: "Spara",
    back: "Tillbaka",
    review: "Förhandsgranska",
    publish: "publicera",
    addItem: (item) => `Lägg till ${item}`,
    email: "Epost",
    cancel: "Avbryt",
  },
  favourite: {
    save: "Spara",
    saved: "Sparad",
    do: "Spara som favorit",
    undo: "Ta bort som favorit",
  },
  assign: {
    section: {
      title: "Ge någon ansvar för avsnittet",
      description: "Här väljer du vem som ska ansvara för avsnittet samt när arbetet ska vara klart.",
    },
    requirement: {
      title: "Ge någon ansvar för kravet",
      description: "Här väljer du vem som ska ansvara för kravet samt när arbetet ska vara klart.",
    },
    choose_user: "Välj kollega",
    responsible: "Ansvarig",
    deadline: "Klart senast",
    assigned: "Tilldelad",
    button_assign: "Tilldela",
    button_update: "Uppdatera",
  },
  comment: {
    section: {
      title: "Kommentera avsnittet",
      description: "Här kommenterar du avsnittet.",
    },
    requirement: {
      title: "Kommentera kravet",
      description: "Här kommenterar du kravet.",
    },
    no_comments: "Det finns inga kommentarer",
  },
  footer: {
    about: {
      title: "Om tjänsten",
      text: "Byggföretagen KMA är en digital stödtjänst från Byggföretagen som syftar till att hjälpa företag med certifieringsprocessen - från start till certifiering och vidare i det dagliga arbetet med KMA.",
      linkText: "Gå till byggforetagen.se",
    },
    support: {
      title: "Tekniska problem?",
      title2: "Kontakta supporten",
      telephone: "031-708 41 22",
      hours: "Öppet vardagar 9-17",
      email: "info@bkma.se",
    },
  },
  start: {
    welcome: "Hej",
    welcomeText: "Du är nu inloggad i Byggföretagens verksamhetsledningssystem KMA (BKMA).",
    ServiceAdmin: [
      "Du har rollen som administratör för hela systemet och har därmed möjlighet att göra förändringar i nästan alla delar av systemet. Tänk på att dina ändringar ofta slår igenom ut till alla användare.",
      "Ett tips är att du följer de instruktioner som finns samt gör kopior på viktiga texter i samband med uppdateringar",
      "Vi hoppas du trivs i systemet och vill utvecklas tillsammans med oss!",
    ],
    CompanyAdmin: [
      "Du har rollen som ert företags administratör och har därmed möjlighet att göra förändringar i de flesta delar av systemet.",
      "Ett tips är att du gör lokala kopia på viktiga texter i samband med uppdateringar.",
      "Vi hoppas du trivs i systemet och vill utvecklas tillsammans med oss!",
    ],
    CompanyEditor: [
      "Du har rollen som redaktör inom ert företag. Det innebär att du kommer åt Byggföretagens mallar och kan redigera i företagets certifieringsguide.",
      "Vi hoppas du trivs i systemet!",
    ],
    Supervisor: [
      "Du har rollen som handledare.",
      "Du kan hjälpa företag att guida dem genom processen, kommentera, tilldela uppgifter mm",
      "Du kan endast se de företag som är kopplad till ditt handledaruppdrag genom Byggföretagen.",
      "Vi hoppas du trivs i systemet och vill utvecklas tillsammans med oss!",
      "Saknar du något av dina företag i listan, kontakta BKMA.",
    ],
    CompanyReader: [
      "Du har rollen som läsare inom ert företag. Det innebär att du kommer åt de dokument som ert företag laddat upp och vill visa för er anställda.",
      "Vi hoppas du trivs i systemet!",
      "Har du fel behörighet, vänligen kontakta ert företagets administratör.",
    ],
    pendingNonValidatedUser: [
      "Grattis! Ditt företag är anslutet till BKMA.",
      "För att få tillgång till tjänsten behöver en administratör på företaget godkänna dig. De hittar din förfrågan på startsidan inne i tjänsten. Kontakta ansvariga på ditt företag för en mer skyndsam process.",
    ],
    pendingNonValidatedMultiCompanyUser: [
      "Du är godkänd användare på bolag ",
      "För att kunna nyttja tjänsten på koncernens andra företag, måste du först bli godkänd i respektive bolag. Kontakta ansvariga på företagen för en mer skyndsam process.",
    ],
    blockedNonValidatedUser: [
      "Åtkomst nekad, ej godkänd som användare",
      "Om ett misstag uppstått - kontakta ansvariga på företaget för hantering.",
    ],
    baseProduct: ["Vi har samlat dokument och mallar som vi hoppas ska hjälpa er på er i det dagliga KMA-arbetet."],
    startButton: {
      goToManual: "Gå till KMA-Manualen",
      startGuide: "Starta certifieringsguiden",
      goToGuide: "Gå till certifieringsguiden",
      continueGuide: "Fortsätt certifieringen",
      notStarted: "Ej påbörjad",
      guideFinished: "Guiden är slutförd",
      openExportDocsDialog: "Exportera och ladda ner som företagsmanual",
      exportDocsHeading: "Exportera företagsmanual",
      chooseDocsForExport: "Välj vilket innehåll som ska ingå",
      exportDocsButton: "Exportera",
    },
  },
  myActivities: {
    title: "Kalenderhändelser",
    incrementMonth: "Visa nästa månad",
    decrementMonth: "Visa föregående månad",
    bucTag: "BUC",
    byggforetagenTag: "BKMA-evenemang",
    calendarTag: "Årskalender",
    externalLink: "Anmäl dig",
    internalLink: "Läs mer",
    emptyState: "Inga aktiviteter denna månad",
  },
  myNews: {
    title: "Nyheter",
    toggleSectionClose: "Göm nyhetsinnehållet",
    toggleSectionOpen: "Visa nyhetsinnehållet",
  },
  myToDos: {
    title: "Din att-göra-lista",
    sectionLink: "Gå till avsnittet",
    requirementLink: "Gå till kravet",
    sectionAssigned: slotted`Du har tilldelats avsnittet: ${`[sectionName]`}.`,
    requirementAssigned: slotted`Du har tilldelats kravet: ${`[requirementName]`} i avsnittet ${`[sectionName]`}.`,
    assignedBy: slotted`Tilldelad av ${`[assignee]`}`,
    deadlineBy: "Klart senast ",
  },
  supervisorsOverview: {
    title: {
      serviceAdmin: "Handledare",
      companyAdmin: "Handledare",
      companyEditor: "Handledare",
      companyReader: "Handledare",
      supervisor: "Handledare",
    },
    description: {
      serviceAdmin: [
        "Våra handledare är experter på att hjälpa företag i KMA-arbetet. ",
        "Handledarna har olika bakgrund och erfarenhet men alla är mycket kompetenta att leda företag genom en certifieringsprocess. ",
        "Tjänsteadministratören är den enda som kan redigera samtliga handledares uppgifter och deras kontaktuppgifter.",
      ],
      companyAdmin: [
        "Våra handledare är experter på att hjälpa företag i KMA-arbetet. ",
        "Handledarna har olika bakgrund och erfarenhet men alla är mycket kompetenta att leda företag genom en certifieringsprocess. ",
        "Nedan kan ni läsa mer om deras bakgrund och deras kontaktuppgifter.",
      ],
      companyEditor: [
        "Våra handledare är experter på att hjälpa företag i KMA-arbetet. ",
        "Handledarna har olika bakgrund och erfarenhet men alla är mycket kompetenta att leda företag genom en certifieringsprocess. ",
        "Nedan kan ni läsa mer om deras bakgrund och deras kontaktuppgifter.",
      ],
      companyReader: ["Nedan kan ni läsa mer om våra handledare, deras bakgrund och kontaktuppgifter."],
      supervisor: [
        "Nedan kan du läsa mer om dina handledarkollegor runt om i landet, deras bakgrund och kontaktuppgifter.",
      ],
      default: [""],
    },
    notAvailable: "Inte angiven",
    noCompany: "Inget företag hittat",
    noDescription: "Beskrivning saknas.",
  },
  calendar: {
    title: {
      serviceAdmin: "Årskalender",
      companyAdmin: "Årskalender",
      companyEditor: "Årskalender",
      companyReader: "Årskalender",
      supervisor: "Årskalender",
    },
    text: {
      serviceAdmin: [
        "Årskalendern är ett verktyg som är bra att använda till de rutiner som är svåra att fånga i andra forum. ",
        "Årskalendern har inga datum, endast veckor. Tanken är att samma aktivitet återkommer årligen i samma vecka. ",
        "Exempel på lämpliga rubriker finns i exempelmallen som kopplas till företaget av BKMA. ",
        "Företagen utvecklar därifrån själva sin egen årskalender.",
      ],
      companyAdmin: [
        "Årskalendern är ett verktyg som är bra att använda till de rutiner som är svåra att fånga i andra forum. ",
        "Årskalendern har inga datum, endast veckor. Tanken är att samma aktivitet återkommer årligen i samma vecka. ",
        "Exempel på lämpliga rubriker finns i exempelmallen som kopplas till företaget av BKMA. ",
        "Ni utvecklar därifrån själva er egen årskalender.",
      ],
      companyEditor: [
        "Årskalendern är ett verktyg som är bra att använda till de rutiner som är svåra att fånga i andra forum. ",
        "Årskalendern har inga datum, endast veckor. Tanken är att samma aktivitet återkommer årligen i samma vecka. ",
        "Exempel på lämpliga rubriker finns i exempelmallen som kopplas till företaget av BKMA. ",
        "Ni utvecklar därifrån själva er egen årskalender.",
      ],
      companyReader: [
        "Årskalendern är ett verktyg som är bra att använda till de rutiner som är svåra att fånga i andra forum. ",
        "Här kan ni läsa om ert företags årliga aktiviteter.",
      ],
      supervisor: [
        "Årskalendern är ett verktyg som är bra att använda till de rutiner som är svåra att fånga i andra forum. ",
        "Årskalendern har inga datum, endast veckor. Tanken är att samma aktivitet återkommer årligen i samma vecka. ",
        "Exempel på lämpliga rubriker finns i exempelmallen som kopplas till företaget av BKMA. ",
        "Företagen utvecklar därifrån själva sin egen årskalender.",
      ],
      default: [""],
    },
    editActivity: "Redigera kalenderaktivitet",
    addActivity: "Lägg till kalenderaktivitet",
    editCalendar: "Redigera kalender",
    editCalendarActivity: "Redigera aktivitet",
    addCalendar: "Lägg till kalender",
    removeCalendar: "Ta bort kalender",
    viewCalendar: "Visa kalender",
    confirmDeletion: "Är du säker på att du vill ta bort kalendern?",
    toggleSectionClose: "Göm månadens aktiviteter",
    toggleSectionOpen: "Visa månadens aktiviteter",
    exportCalendar: "Exportera kalendern för utskrift",
    editActivityFormLabelTitle: "Titel",
    editActivityFormLabelDescription: "Beskrivning",
    editActivityFormLabelWeekNumber: "Vecka",
    editActivityFormLabelLibraryDocumentsTitle: "Dokumenttitel",
    editActivityFormLabelLibraryDocuments: "Dokument",
    editActivityFormLabelSubmit: "Spara aktivitet",
    editActivityFormLabelCancel: "Avbryt",
    editCalendarFormLabelTitle: "Titel",
    editCalendarFormLabelProductTypes: "Produkt",
    editCalendarFormLabelSubmit: "Spara kalender",
    editCalendarFormLabelCancel: "Avbryt",
    editCalendarFormLabelRemoveLink: "Ta bort kalenderaktivitet",
    editActivityConfirmDeletion: "Är du säker på att du vill ta bort kalenderaktiviteten?",
    removeCalendarLink: "Ta bort kalender",
    managementOnly: "Endast synlig för ledningsgruppen",
  },
  calendars: {
    title: "Alla kalendrar",
    text: "Här ser du alla dina tillgängliga årskalendrar.",
    titleAdmin: "Alla kalendermallar",
    textAdmin: "Skapa nya kalendermallar som företagen sedan ansluts till.",
    createNew: "Skapa ny kalendermall",
    noCalendars: "Hej! Du har tyvärr ingen kalender än. Kontakta din handledare så hjälper hen hemskt gärna till.",
  },
  richTextEditor: {
    bold: "Fetstil",
    italic: "Kursiv",
    underline: "Understruken",
    heading1: "Huvudrubrik",
    heading2: "Underrubrik",
    listOrdered: "Nummerlista",
    listUnordered: "Punktlista",
    link: "Infoga länk",
    linkPrompt: "Skriv url:en för länken",
    placeholder: "Skriv något och formatera med knapparna ovan",
  },
  dev: {
    ariaLabelMissing: "Oh no! Either aria-label or aria-labelledby must be set",
  },
};

export default staticTexts;
