import React from "react";
import ToolsListItem from "./ToolsListItem";
//import { ReactComponent as PrimaryArrowBig } from "icons/Primary-arrow-big.svg";
import __ from "constants/static-texts";

//import ToolsListItem from "./LibraryDocListItem";

function ToolsList({ classNs, tools }) {
  const toolItems = __.tools.toolItems;
  return (
    <ul className={`${classNs}_list`}>
      {toolItems.map((tool, index) => (
        <ToolsListItem key={index} tool={tool} classNs={classNs} />
      ))}
    </ul>
  );
}

export default ToolsList;
